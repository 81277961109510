footer {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 10px;
  text-align: center;
  color: rgb(255, 255, 255)
  
}

#footer-text {
  margin: 10px;
  display: block;
}
