html,
body,
#react-root {
  background: linear-gradient(90deg, rgba(129,194,254,1) 0%, rgba(218,121,194,1) 100%);
}

/* body {
  margin: 0px;
  background-image: var(--bg-image);
} */

img {
  display: inline-block;
  max-width: 100%;
}

/* --------------------------------------------------------------------------------
Container
-------------------------------------------------------------------------------- */

.container {
  padding: 20px;
}

.qr-container {
  padding: 20px;
  padding-top: 80px;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 50vh;
}


.scroll-container {
  overflow: scroll;
  scrollbar-color: red;
  scrollbar-width: auto;
}
/* --------------------------------------------------------------------------------
Button
-------------------------------------------------------------------------------- */

.btn {
  display: inline-block;
  padding: 10px 20px;
  border-color: #072541;
  border-width: 1px 1px 3px;
  border-radius: 4px;
  font-size: 1.1rem;
  outline: 0;
  cursor: pointer;
  /* hacky way of centering login button */
  /* margin-left: 100px; */
}

.btn_sizeMin {
  min-height: 50px;
  min-width: 100px;
}

.btn_sizeSm {
  padding: 10px 15px;
  font-size: 0.8rem;
}

.btn_sizeFull {
  display: block;
  width: 100%;
}

/* .btn:hover {
  background-color: #008183;
  text-decoration: none;
} */

/* This line makes the "Register" and "Login" buttons look like links */
.btn_asLink {
  padding: 0;
  border-width: 0;
  border-radius: 0;
  background: none;
  font: inherit;
}

.btn_asLink:hover {
  background-color: transparent;
}

/* --------------------------------------------------------------------------------
Alert
-------------------------------------------------------------------------------- */

.alert {
  box-sizing: border-box;
  padding: 15px 10px;
  margin: 15px 0;
  border-radius: 3px;
  font-size: 0.8rem;
}

/* --------------------------------------------------------------------------------
Form Treatment
-------------------------------------------------------------------------------- */

.formPanel {
  box-sizing: border-box;
  width: 350px;
  max-width: 90%;
  padding: 25px;
  margin: auto;
  background-color: 'transparent';
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 20vh;

}

input {
  margin: 5px;
  margin-bottom: 10px;
  border: 1px solid black;
  border-radius: 5px;
}

/* --------------------------------------------------------------------------------
Table
-------------------------------------------------------------------------------- */

.table-div {
  width: auto;
  text-align: left;
  white-space: normal;
  word-wrap: break-word;
  table-layout: auto;
  overflow-y: scroll;
}
table, th, td {
  /* border: 1px solid black ; */
  border-collapse: collapse;
  height: 2.15em;
}
th, td {
  padding: .5em;
  min-width: 5em;
  width: auto;
}
thead {
  /* background: rgb(166, 137, 244); */
  color: darkslategray;
}
/* tbody tr:nth-child(3n+0) {
  background: rgb(165, 227, 252);
}
tbody tr:nth-child(even) {
  background: rgb(242, 191, 223);
}

tbody tr:nth-child(odd) {
  background: rgb(255, 224, 244);
} */

.react-bootstrap-table {
  table-layout: auto;
  overflow-y: auto;
  overflow: scroll;
  }

#api-table {
  overflow: scroll;
}

.react-bootstrap-table tab {
  color: darkslategray;
}

.user-profile {
  background: #FF7A59;
  color: #FF7A59;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  font-size:xx-large
  
}

.link {
  color: rgb(18, 126, 150);
  text-decoration: underline;
}

/* .profile {
  display: inline;
} */

#map-container {
  width: 400px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 20px;
  margin-right: 20px;

}

#need-to-pee {
  width: 125px;
  margin: 50px auto 50px;
}

.text {
  margin: auto;
}

#thankyou {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  align-content: center;
}

h1, h3, h5 {
  text-align: center;
}

h5.about {
  text-align: justify;
}

h6.open {
color: green;
}

h6.closed {
  color: red;
}

.linkInDrawer:link {
  text-decoration: none;
  color: black;
}

.app-bar {
  position: sticky;
}

#about-header {
  margin-top: 100px;
  margin-bottom: 25px;
  font-weight: bold;
}

/* Shoutout to user Woodrow Barlow on Stack exchange for the wiggle animation
https://stackoverflow.com/questions/38132700/css-wiggle-shake-effect */
@keyframes wiggle {
  0% { transform: rotate(0deg); }
 20% { transform: rotate(0deg); }
 25% { transform: rotate(5deg); }
 35% { transform: rotate(-5deg); }
50% { transform: rotate(0deg); }
}

h1.wiggle {
animation: wiggle 2.5s 1;
}

h1.wiggle:hover {
  animation: none;
}

/* --------------------------------------------------------------------------------
Search Bar
-------------------------------------------------------------------------------- */
.searchBar {
  display: flex;
  flex-grow: 1;
  margin: 5px;
}

/* --------------------------------------------------------------------------------
Avatar circle
-------------------------------------------------------------------------------- */

.circle {
  width: 150px;
  height: 150px;
  line-height: 130px;
  border-radius: 50%;
  font-size: 100px;
  color: #FFF6F6;
  text-align: center;
  background: deeppink;
  margin: 20px auto 5px;
  text-shadow: 2px 2px 5px darkmagenta;
}

.join-date {
  font-size: medium;
  text-align: center;
  margin-bottom: 20px;
}

.comments {
  margin-left: 20px;
  text-align: left;
}

.comment-name {
  margin-left: 30px;
  margin-top: 20px;
  font-size: 1.2rem;
  text-decoration: underline;
  text-align: left;
}

#delete-comment {
  display: block;
  margin-left: auto;
  margin-right: 40px;
}

#comment-content {
  margin-left: 60px;
  /* display: inline; */
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
